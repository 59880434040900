import dayjs from 'dayjs/esm'
import { StatusTracker as StatusTrackerType } from '/~/types/api'
import { createDate, formatDate } from '/~/utils/format/date'

export class StatusTracker {
  tracker: StatusTrackerType['tracker']
  status: StatusTrackerType['status']
  happenedAt: dayjs.Dayjs | null

  constructor(item: StatusTrackerType) {
    this.tracker = item.tracker
    this.status = item.status
    this.happenedAt = item.happenedAt ? createDate(item.happenedAt) : null
  }

  get title(): string {
    const trackers = {
      payment_initiated: 'Payment initiated',
      funds_received: 'Funds received',
      funds_disbursed: 'Funds disbursed',
    }

    return trackers[this.tracker]
  }

  get isStatusCompleted(): boolean {
    return this.status === 'completed'
  }

  get isStatusDeclined(): boolean {
    return ['declined', 'failed'].includes(this.status)
  }

  get isStatusPending(): boolean {
    return ['pending'].includes(this.status)
  }

  get isStatusCancelled(): boolean {
    return this.status === 'cancelled'
  }

  get happenedAtDate(): string | null {
    return formatDate('daymonthyearlong', this.happenedAt) ?? null
  }

  get happenedAtTime(): string | null {
    return dayjs(this.happenedAt).format('hh:mm a') ?? null
  }
}
